import React from "react";
import { useRouter } from "next/router";
import { SnackbarProvider } from "notistack";
// import "@/styles/remixicon.scss";
// import 'react-tabs/style/react-tabs.css';
import "swiper/css";
import "swiper/css/bundle";
import { CacheProvider } from "@emotion/react";
import createEmotionCache from "@/utils/createEmotionCache";
import { CookiesProvider } from "react-cookie";
import { DefaultSeo } from "next-seo";

// Chat Styles
// import "@/styles/chat.scss";
// Globals Styles
import "@/styles/_color-change.scss";
import "@/styles/theme.scss";
import "@/styles/logo.scss";
import "@/styles/globals.scss";
import "@/styles/mui-customization.scss";
// Rtl Styles
// import "@/styles/rtl.scss";
// Dark Mode Styles
import "@/styles/dark.scss";

// Theme Styles
import theme from "@/utils/theme";
import { ThemeProvider, CssBaseline } from "@mui/material";
import Layout from "@/components/Layout";

// MSAL imports
import { MsalProvider /*useMsal*/ } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "@/utils/authConfig";
import { CustomNavigationClient } from "@/utils/NavigationClient";

import AnalyticsProvider from "@/utils/AnalyticsProvider";
import TokenProvider from "@/utils/TokenProvider";

const clientSideEmotionCache = createEmotionCache();
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  // console.log(event.eventType);
  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    // window.dispatchEvent(new Event("storage"));
  }

  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS ||
    event.eventType === EventType.LOGOUT_SUCCESS ||
    event.eventType === EventType.INITIALIZE_END
  ) {
    document.dispatchEvent(
      new CustomEvent("msalAuthentication", { detail: event.eventType })
    );
    // console.log(event.eventType);
  } else if (
    event.eventType === EventType.ACCOUNT_REMOVED &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.logoutRedirect().catch((error) => {});
  } else if (
    event.eventType === EventType.ACCOUNT_ADDED &&
    msalInstance.getAllAccounts().length === 0
  ) {
    const account = event.payload;
    // console.log(account);
    msalInstance.setActiveAccount(account);
    msalInstance.ssoSilent(loginRequest).catch(() => {});
  }
});

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}) {
  const router = useRouter();
  const navigationClient = new CustomNavigationClient(router);
  msalInstance.setNavigationClient(navigationClient);

  const canonicalUrl = (
    `https://jaxen.ai` + (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0];

  return (
    <AnalyticsProvider
      googleAnalyticsTrackingID={"G-MR913WFMK7"}
      googleTagID={"GTM-PL98RGV"}
      // requireConsent={true}
    >
      <DefaultSeo canonical={canonicalUrl} />
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CookiesProvider>
            <CssBaseline />
            {/* <ClientSideNavigation pca={msalInstance}> */}
            <MsalProvider instance={msalInstance}>
              <TokenProvider>
                <SnackbarProvider>
                  <Layout>
                    {/* <Pages /> */}
                    <Component {...pageProps} />
                  </Layout>
                </SnackbarProvider>
              </TokenProvider>
            </MsalProvider>
            {/* </ClientSideNavigation> */}
          </CookiesProvider>
        </ThemeProvider>
      </CacheProvider>
    </AnalyticsProvider>
  );
}

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
// function ClientSideNavigation({ pca, children }) {
//   const navigate = useRouter();
//   const navigationClient = new CustomNavigationClient(navigate);
//   pca.setNavigationClient(navigationClient);

//   // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
//   const [firstRender, setFirstRender] = useState(true);
//   useEffect(() => {
//     setFirstRender(false);
//   }, []);

//   if (firstRender) {
//     return null;
//   }

//   return children;
// }

// function Pages() {
//   const { instance } = useMsal();
//   // const [status, setStatus] = useState(null);

//   useEffect(() => {
//     const callbackId = instance.addEventCallback((event) => {
//       if (
//         (event.eventType === EventType.LOGIN_SUCCESS ||
//           event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
//         event.payload.account
//       ) {
//         /**
//          * For the purpose of setting an active account for UI update, we want to consider only the auth
//          * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
//          * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
//          * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
//          */
//         if (
//           event.payload.idTokenClaims["tfp"] === b2cPolicies.names.editProfile
//         ) {
//           // retrieve the account from initial sing-in to the app
//           const originalSignInAccount = instance
//             .getAllAccounts()
//             .find(
//               (account) =>
//                 account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
//                 account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
//                 account.idTokenClaims["tfp"] === b2cPolicies.names.signUpSignIn
//             );

//           let signUpSignInFlowRequest = {
//             authority: b2cPolicies.authorities.signUpSignIn.authority,
//             account: originalSignInAccount,
//           };

//           // silently login again with the signUpSignIn policy
//           instance.ssoSilent(signUpSignInFlowRequest);
//         }
//       }

//       // if (
//       //   event.eventType === EventType.SSO_SILENT_SUCCESS &&
//       //   event.payload.account
//       // ) {
//       //   setStatus("ssoSilent success");
//       // }
//     });

//     return () => {
//       if (callbackId) {
//         instance.removeEventCallback(callbackId);
//       }
//     };
//     // eslint-disable-next-line
//   }, []);

//   return (
//     <Routes>
//       <Route path="/" element={<Home />} />
//       <Route path="/MotivationLab" element={<MotivationLab />} />
//     </Routes>
//   );
// }

export default MyApp;

// export function reportWebVitals(metric) {
//   console.log(metric);
// }
