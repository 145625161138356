import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Link from "next/link";
import styles from "./SubMenu.module.scss";
import { useRouter } from "next/router";
import { TokenContext } from "@/utils/TokenProvider";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SidebarLabel = styled("span")(({ theme }) => ({
  position: "relative",
  top: "-3px",
}));

const SubMenu = ({ item, toogleActive }) => {
  const [subnav, setSubnav] = useState(false);
  // const showSubnav = () => setSubnav(!subnav);
  const [currentPath, setCurrentPath] = useState("");
  const router = useRouter();

  const { userId } = React.useContext(TokenContext);

  useEffect(() => {
    setCurrentPath(router.pathname);
    const mainPath = item.path;
    const itemPaths = item.subNav?.map((item) => item.path);
    // concatenate main path and sub paths into one array of paths to check against taking into consideration that itemPaths might be empty
    const allPaths = itemPaths ? [mainPath, ...itemPaths] : [mainPath];
    if (allPaths.includes(router.pathname)) {
      setSubnav(true);
    } else {
      setSubnav(false);
    }
  }, [router.pathname]);

  // Handle link click
  // const handleItemLinkClick = (e) => {
  //   if (item.subNav) {
  //     // e.preventDefault();
  //     // showSubnav();
  //   } else {
  //     // Check for viewport size, then if view port is less than 'lg', toogleActive. If viewport is higher than 'lg' do nothing.
  //     if (window.innerWidth < 1200) {
  //       toogleActive();
  //     }
  //   }
  // };

  // Handle sub-item link click
  const handleSubItemLinkClick = (e) => {
    if (window.innerWidth < 1200) {
      toogleActive();
    }
  };

  return (
    ((item.requiresSubscription && userId) || !item.requiresSubscription) && (
      <div>
        <Link
          href={{
            pathname: item.path,
            query: router.query,
          }}
          // as={item.path}
          onClick={handleSubItemLinkClick}
          className={`${styles.sidebarLink} ${
            currentPath === item.path && "sidebarLinkActive"
          }`}
        >
          <div>
            {item.icon}
            <SidebarLabel className="ml-1">{item.title}</SidebarLabel>
          </div>
          {/* <div>{item.subNav && subnav ? <KeyboardArrowDownIcon /> : null}</div> */}
        </Link>
        {subnav && (
          <div className={styles.subMenu}>
            {item.subNav?.map((item, index) => {
              return (
                <Link
                  href={{
                    pathname: item.path,
                    query: router.query,
                  }}
                  // as={item.path}
                  key={index}
                  className={`${styles.sidebarLink} ${styles.subMenuItem} ${
                    currentPath === item.path && "sidebarLinkActive"
                  }`}
                  onClick={handleSubItemLinkClick}
                >
                  {/* {item.icon} */}
                  {item.title}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    )
  );
};

export default SubMenu;
