import React, { useState, useEffect } from "react";
import Head from "next/head";
// import { useRouter } from "next/router";
import LeftSidebar from "@/components/Layout/LeftSidebar";
import TopNavbar from "@/components/Layout/TopNavbar";
import Footer from "@/components/Layout/Footer";
import ScrollToTop from "@/components/Layout/ScrollToTop";
// import ControlPanelModal from "@/componentsControlPanelModal";
import theme from "@/utils/theme";

const Layout = ({ children }) => {
  // const router = useRouter();
  const [active, setActive] = useState(false);

  const toogleActive = () => {
    setActive(!active);
  };

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // Listen for changes to the color scheme preference
    const handleChange = (e) => {
      // if it's dark -> set dark theme_color
      // console.log(e.matches);
      setDarkMode(e.matches);
      // theme.palette.text.primary = e.matches
      //   ? "hsla(0, 0%, 100%, 80%)"
      //   : "hsla(0, 0%, 0%, 80%)";
      // theme.palette.text.secondary = e.matches
      //   ? "hsla(0, 0%, 100%, 60%)"
      //   : "hsla(0, 0%, 0%, 60%)";
      if (e.matches) {
        document
          .querySelector('meta[name="theme-color"]')
          .setAttribute("content", "#6b00b3");
      }
      // if it's light -> set light theme_color
      else {
        document
          .querySelector('meta[name="theme-color"]')
          .setAttribute("content", "#a31aff");
      }
    };
    handleChange(mediaQuery);
    mediaQuery.addEventListener("change", handleChange);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  return (
    <>
      <Head>
        <title>Jaxen</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <div
        className={`main-wrapper-content${active ? " active" : ""}${
          darkMode ? " dark" : ""
        }`}
      >
        <TopNavbar toogleActive={toogleActive} />
        <LeftSidebar toogleActive={toogleActive} />
        {/*className="main-content"*/}
        <div className="page-content">
          {children}
          <Footer />
        </div>

        {/* ScrollToTop */}
        <ScrollToTop />

        {/* <ControlPanelModal /> */}
      </div>
    </>
  );
};

export default Layout;
