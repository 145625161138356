import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button, SvgIcon } from "@mui/material";
// import SearchForm from "./SearchForm";
// import Email from "./Email";
import Notification from "./Notification";
import Profile from "./Profile";
import Tooltip from "@mui/material/Tooltip";
// import CurrentDate from "./CurrentDate";

import { useMsal } from "@azure/msal-react";
import { b2cPolicies } from "@/utils/authConfig";

import { TokenContext } from "@/utils/TokenProvider";
import { AnalyticsContext } from "@/utils/AnalyticsProvider";

const TopNavbar = ({ toogleActive }) => {
  const { isAuthenticated, userId } = React.useContext(TokenContext);
  const { instance: msalInstance } = useMsal();
  const analytics = React.useContext(AnalyticsContext);

  const handleSignUpButtonClick = () => {
    if (!isAuthenticated && !userId) {
      analytics.action("sign_up_button_click", "navbar");
      msalInstance.acquireTokenRedirect(b2cPolicies.authorities.signUp);
    }
  };

  return (
    <>
      <topnavbardark>
        <AppBar
          color="inherit"
          sx={{
            backgroundColor: "var(--accent2)", //fff
            backgroundImage:
              "conic-gradient(from 183deg at 35% 150%, var(--accent2-dark) 0%, var(--accent2-dark) 20%, var(--accent2) 35%, var(--accent2) 50%, var(--accent2-light) 65%, var(--accent4) 71%, var(--accent3) 75%, var(--accent2) 100%)",
            // boxShadow: "0px 4px 20px rgba(47, 143, 232, 0.07)",
            py: "6px",
            mb: "30px",
            position: "sticky",
          }}
          className="top-navbar-for-dark"
        >
          <Toolbar>
            <Tooltip title="Hide/Show" arrow>
              <IconButton
                size="sm"
                edge="start"
                color="inherit"
                onClick={toogleActive}
              >
                {/* <i
                  className="ri-menu-fill"
                  style={{ color: "var(--accent3)" }}
                ></i> */}
                <SvgIcon style={{ color: "var(--accent3)" }}>
                  <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z" />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <svg
              version="1.1"
              id="JaxenLogo"
              // xmlns="https://www.w3.org/2000/svg"
              // xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 25"
              // style="enable-background:new 0 0 100 25;"
              // xml:space="preserve"
            >
              <g id="JaxenText">
                <path
                  class="st0"
                  d="M39.69,24.28l-0.96-1.22c0.6-0.5,1.15-0.96,1.65-1.39c0.5-0.43,0.93-0.88,1.29-1.37
c0.36-0.48,0.64-1.05,0.84-1.7c0.2-0.65,0.3-1.44,0.3-2.39V1.84h2.3v14.38c0,1.05-0.13,1.96-0.38,2.72
c-0.25,0.77-0.61,1.45-1.09,2.04c-0.47,0.59-1.04,1.15-1.71,1.67S40.53,23.72,39.69,24.28z"
                />
                <path
                  class="st0"
                  d="M53.16,19.53c-0.83,0-1.55-0.15-2.15-0.45c-0.6-0.3-1.07-0.71-1.39-1.22c-0.32-0.51-0.49-1.09-0.49-1.74
c0-0.81,0.21-1.48,0.64-2.01c0.42-0.53,1.12-0.96,2.09-1.29c0.97-0.33,2.28-0.59,3.92-0.78c-0.03,0.19-0.1,0.42-0.21,0.68
c-0.11,0.26-0.28,0.51-0.52,0.73c-0.24,0.22-0.57,0.37-1,0.43c-0.7,0.11-1.24,0.28-1.6,0.49c-0.36,0.22-0.61,0.46-0.74,0.74
s-0.2,0.56-0.2,0.86c0,0.49,0.18,0.91,0.53,1.28c0.35,0.36,0.83,0.55,1.44,0.55c0.54,0,1.09-0.14,1.64-0.41s1.06-0.71,1.52-1.32
v-4.49c0-0.95-0.23-1.73-0.69-2.32c-0.46-0.6-1.24-0.89-2.33-0.89c-0.43,0-0.86,0.04-1.28,0.13c-0.42,0.09-0.92,0.24-1.5,0.44
l-0.55-1.49c0.78-0.34,1.48-0.56,2.08-0.66c0.6-0.1,1.16-0.16,1.69-0.16c1.54,0,2.73,0.45,3.58,1.34s1.27,2.15,1.27,3.77v3.43
c0,0.96,0.04,1.78,0.11,2.45s0.17,1.23,0.27,1.65h-1.63c-0.22-0.14-0.4-0.31-0.53-0.53c-0.13-0.22-0.24-0.45-0.34-0.7l-0.02-0.43
l-0.26,0.43c-0.61,0.61-1.17,1-1.69,1.19C54.29,19.44,53.74,19.53,53.16,19.53z"
                />
                <path
                  class="st0"
                  d="M62.36,19.29l3.7-5.4c0.22,0.26,0.38,0.55,0.5,0.87c0.12,0.32,0.17,0.66,0.14,1.01
c-0.03,0.35-0.15,0.69-0.38,1.03l-1.63,2.5H62.36z M70.69,19.29c-0.66-1.05-1.32-2.09-1.98-3.11c-0.66-1.03-1.34-2.06-2.03-3.1
l-4.1-6.19h2.52l2.57,4.3l0.14,0.5l0.17-0.5l2.62-4.3h2.35l-4.08,6.1l4.32,6.31H70.69z"
                />
                <path
                  class="st0"
                  d="M81.2,19.53c-0.88,0-1.68-0.16-2.39-0.49s-1.33-0.79-1.84-1.37c-0.51-0.59-0.9-1.28-1.17-2.08
c-0.27-0.8-0.41-1.67-0.41-2.61c0-1.3,0.24-2.42,0.71-3.37c0.48-0.95,1.15-1.68,2.03-2.2c0.88-0.52,1.92-0.77,3.13-0.77
c0.82,0,1.55,0.18,2.18,0.55c0.63,0.36,1.14,0.86,1.53,1.49c0.39,0.63,0.63,1.34,0.72,2.14c0.09,0.8,0.01,1.62-0.26,2.48h-6.96
c0.17-0.46,0.41-0.84,0.71-1.15c0.31-0.31,0.75-0.46,1.33-0.46h3.05c0.07-0.62,0-1.17-0.21-1.67c-0.21-0.5-0.52-0.9-0.94-1.19
s-0.88-0.44-1.4-0.44c-0.67,0-1.26,0.18-1.78,0.54c-0.51,0.36-0.91,0.89-1.2,1.58c-0.29,0.69-0.43,1.53-0.43,2.51
c0,0.95,0.17,1.79,0.5,2.51c0.33,0.72,0.79,1.28,1.36,1.69c0.58,0.4,1.23,0.61,1.96,0.61c0.55,0,1.08-0.06,1.57-0.17
c0.5-0.12,1.08-0.35,1.76-0.69l0.6,1.58c-0.77,0.39-1.51,0.66-2.23,0.8C82.42,19.46,81.77,19.53,81.2,19.53z"
                />
                <path
                  class="st0"
                  d="M89.76,19.29V9.91c0-0.45-0.03-0.92-0.08-1.43c-0.05-0.5-0.15-1.04-0.28-1.6h1.73
c0.38,0.43,0.64,0.86,0.76,1.28c0.12,0.42,0.18,0.88,0.18,1.36v9.77H89.76z M97.63,19.29v-9.02c0-0.55-0.12-1.01-0.37-1.36
c-0.25-0.36-0.62-0.53-1.12-0.53c-0.19,0-0.36,0.02-0.5,0.06c-0.14,0.04-0.32,0.08-0.53,0.13c-0.46,0.1-0.9,0.11-1.3,0.01
c-0.4-0.1-0.77-0.32-1.1-0.66c0.86-0.5,1.57-0.84,2.14-1.01c0.57-0.17,1.07-0.26,1.51-0.26c0.64,0,1.23,0.12,1.78,0.35
s0.98,0.63,1.31,1.19s0.49,1.34,0.49,2.33v8.78H97.63z"
                />
              </g>
              <g id="JaxenHead">
                <g>
                  <g>
                    <path
                      class="st0"
                      d="M15.95,0.42c-8.26,0-14.96,6.7-14.96,14.96c0,8.26,6.7,8.9,14.96,8.9c8.26,0,14.96-0.64,14.96-8.9
        C30.91,7.12,24.21,0.42,15.95,0.42z M15.95,23.28c-9.46,0-13.26-1.18-13.26-7.9c0.77-18.53,25.77-18.52,26.53,0
        C29.22,22.1,25.41,23.28,15.95,23.28z"
                    />
                  </g>
                </g>
                <path
                  class="st0"
                  d="M23.4,14.58H8.5c-1.6,0-2.89-1.29-2.89-2.89v0c0-1.6,1.29-2.89,2.89-2.89h14.9c1.6,0,2.89,1.29,2.89,2.89v0
C26.29,13.29,24.99,14.58,23.4,14.58z"
                />
                <path
                  class="st0"
                  d="M1.98,10.83c-2.63,0.1-2.63,8.02,0,8.11V10.83z"
                />
                <path
                  class="st0"
                  d="M29.91,10.83c2.63,0.1,2.63,8.02,0,8.11V10.83z"
                />
                <path
                  class="st0"
                  d="M13.4,18.06c1.4,2.04,3.7,2.02,5.09,0C16.98,19.58,14.9,19.56,13.4,18.06z"
                />
                <path
                  class="st0"
                  d="M31.19,1.67c0.31,0.04,0.54,0.33,0.5,0.64c0,0-1.14,8.75-1.14,8.75c-0.04,0.31-0.33,0.54-0.64,0.5
c-0.31-0.04-0.54-0.33-0.5-0.64l1.14-8.75C30.59,1.86,30.87,1.63,31.19,1.67z"
                />
                <path
                  class="st0"
                  d="M0.69,1.67C0.38,1.72,0.15,2,0.19,2.32c0,0,1.14,8.75,1.14,8.75c0.04,0.31,0.33,0.54,0.64,0.5
s0.54-0.33,0.5-0.64L1.33,2.17C1.29,1.86,1,1.63,0.69,1.67z"
                />
              </g>
              <path
                id="JaxenEye"
                class="st1"
                d="M18.14,11.69c-0.07,2.89-4.3,2.89-4.38,0C13.83,8.8,18.06,8.8,18.14,11.69z"
              />
            </svg>

            {/* Search form */}
            {/* <SearchForm /> */}

            <Typography component="div" sx={{ flexGrow: 1 }}></Typography>

            <Stack direction="row" spacing={{ xs: 0.5, md: 2 }}>
              {/* CurrentDate */}
              {/* <CurrentDate /> */}

              {/* Email */}
              {/* <Email /> */}

              {!isAuthenticated && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  sx={{
                    display: { xs: "none", sm: "block" },
                  }}
                  //   marginTop: 2,
                  //   marginX: "auto",
                  //   width: "250px",
                  //   height: "60px",
                  // }}
                  // on click navigate to "Subscribe" page
                  // href="/Subscribe"
                  onClick={handleSignUpButtonClick}
                >
                  SIGN UP
                </Button>
              )}

              {/* Notification */}
              {/* <Notification /> */}

              {/* Profile */}
              <Profile />
            </Stack>
          </Toolbar>
        </AppBar>
      </topnavbardark>
    </>
  );
};

export default TopNavbar;
