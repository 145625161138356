import React, { useRef } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest, b2cPolicies } from "@/utils/authConfig";

const TokenContext = React.createContext();

const TokenProvider = ({ children }) => {
  const [idToken, setIdToken] = React.useState(null);
  const [userId, setUserId] = React.useState(null);
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const intervals = useRef([]);
  const authenticationListener = useRef(null);
  const tokenPromise = useRef(null);

  React.useEffect(() => {
    // if (instance.getActiveAccount()?.homeAccountId) {
    //   console.log(
    //     "Initialize token from cache: " +
    //       instance
    //         .getTokenCache()
    //         .storage?.getIdToken(
    //           instance.getActiveAccount(),
    //           instance.getTokenCache().storage?.getTokenKeys()
    //         )?.secret
    //   );
    // }

    function clearIntervals() {
      // console.log("Clearing intervals");
      intervals.current.forEach((interval) => {
        clearInterval(interval);
        intervals.current = intervals.current.filter((i) => i !== interval);
      });
    }

    async function acquireToken(invoker) {
      // console.log(intervals.current);
      // if (!invoker) invoker = "useEffect";
      if (
        isAuthenticated &&
        instance.getActiveAccount() &&
        inProgress === InteractionStatus.None
      ) {
        if (!tokenPromise.current) {
          // console.log("TokenProvider creating new promise...");
          // console.log("TokenProvider acquiring token...");
          // console.log(instance.getActiveAccount());
          const silentRequest = {
            scopes: [
              "https://creatorlabsai.onmicrosoft.com/f388192e-5944-4314-be13-74ba8784d1aa/user.read",
            ],
            account: instance.getActiveAccount(),
            forceRefresh: invoker === "useEffect" ? false : true,
            authority:
              "https://login.jaxen.ai/creatorlabsai.onmicrosoft.com/" +
              instance.getActiveAccount().idTokenClaims["tfp"],
          };
          // console.log(silentRequest);
          tokenPromise.current = instance.acquireTokenSilent(silentRequest);
          clearIntervals();
          intervals.current.push(
            setInterval(acquireToken, 1000 * 60 * 60, "setInterval")
          );
        }
        tokenPromise.current
          .then((authResult) => {
            setIdToken(authResult.idToken);
            setUserId(authResult.account.homeAccountId);
            // console.log(
            //   `${invoker} caused TokenProvider to acquire token for ${authResult.account.homeAccountId} with value ${authResult.idToken}`
            // );
            // console.log(authResult.idToken);
            // console.log(authResult.account.homeAccountId);
          })
          .then(() => {
            tokenPromise.current = null;
            document.dispatchEvent(
              new CustomEvent("TokenProviderAcquireToken", {
                detail: invoker,
              })
            );
          })
          .catch((error) => {
            console.log(error);
            let username = instance.getActiveAccount()?.username;
            if (
              error.name === "InteractionRequiredAuthError" ||
              error.name === "BrowserAuthError" ||
              error.name === "ClientConfigurationError" ||
              error.name === "ClientAuthError"
            ) {
              // console.log(
              //   "Cannot authenticate user. TokenProvider clearing token..."
              // );
              // console.log(error);
              console.log("Logging out due to error: " + error.name);
              tokenPromise.current = null;
              setIdToken(null);
              setUserId(null);
              clearIntervals();
              let redirectUri = `/Login?loginHint=${username}&redirectUri=${window.location.href}`;
              if (window.location.search) {
                redirectUri = `${redirectUri}&${window.location.search}`;
              }
              instance.logout({
                postLogoutRedirectUri: redirectUri,
              });
            }
            // } else if (
            // ) {
            //   tokenPromise.current = null;
            //   setIdToken(null);
            //   setUserId(null);
            //   clearIntervals();
            //   instance.logout({
            //     onRedirectNavigate: () => {
            //       instance.loginRedirect({
            //         authority: b2cPolicies.authorities.signUpSignIn.authority,
            //         scopes: loginRequest.scopes,
            //         prompt: "login",
            //         loginHint: username,
            //         onRedirectNavigate: () => {
            //           return false;
            //         },
            //       });
            //     },
            //   });
          });
      }
      // console.log(intervals.current);
    }
    setUserId(instance.getActiveAccount()?.homeAccountId);
    authenticationListener.current = document.addEventListener(
      "msalAuthentication",
      (e) => acquireToken(e.detail)
    );
    acquireToken("useEffect");

    return () => {
      clearIntervals();
      document.removeEventListener(
        "msalAuthentication",
        authenticationListener.current
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, isAuthenticated, inProgress]);

  const value = {
    idToken: idToken,
    userId: userId,
    isAuthenticated: isAuthenticated,
    firstName: instance.getActiveAccount()?.idTokenClaims.given_name,
    lastName: instance.getActiveAccount()?.idTokenClaims.family_name,
  };

  return (
    <TokenContext.Provider value={value}>{children}</TokenContext.Provider>
  );
};

export default TokenProvider;
export { TokenContext };
