import React from "react";
import dynamic from "next/dynamic";

// For Analytics Provider
import ReactGA from "react-ga4"; // Requires: "react-ga4": "^2.1.0"
import { useRouter } from "next/router";

const AnalyticsContext = React.createContext();

const AnalyticsProvider = ({
  children,
  googleAnalyticsTrackingID,
  googleTagID,
  requireConsent = false,
}) => {
  const ConsentBanner = dynamic(() => import("./ConsentBanner"));
  const TagManager = googleTagID ? require("react-gtm-module") : null; // Requires: "react-gtm-module": "^2.0.11"

  const [consent, setConsent] = React.useState(requireConsent ? null : true);
  const [consentDialogOpen, setConsentDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (requireConsent) {
      const localConsentData = localStorage.getItem("consent");
      if (localConsentData) {
        setConsent(localConsentData);
      } else {
        setConsentDialogOpen(true);
      }
    }
  }, [requireConsent]);

  React.useEffect(() => {
    if (consent) {
      localStorage.setItem("consent", consent);
    }
  }, [consent]);

  // Initialize Google Analytics
  React.useEffect(() => {
    if (googleAnalyticsTrackingID && ReactGA && consent === "all") {
      console.debug("Initializing Google Analytics");
      // Any changes here must be reflected in csp-header.js as it will affect the script being injected by ReactGA4.
      // Initialize Google Analytics
      if (googleAnalyticsTrackingID) {
        ReactGA.initialize([
          {
            trackingId: googleAnalyticsTrackingID,
            dataLayer: window.gaDataLayer,
            dataLayerName: "gaDataLayer",
          },
        ]);
      }

      // Return resetting function
      return () => {
        ReactGA.reset();
      };
    }
  }, [googleAnalyticsTrackingID, consent]);

  // Initialize Google Tag Manager
  React.useEffect(() => {
    if (googleTagID && TagManager && consent === "all") {
      console.debug("Initializing Google Tag Manager");
      // Any changes here must be reflected in csp-header.js
      TagManager.initialize({
        gtmId: googleTagID,
        dataLayer: window.gtmDataLayer,
        dataLayerName: "gtmDataLayer",
      });
    }
  }, [TagManager, googleTagID, consent]);

  const router = useRouter();

  React.useEffect(() => {
    pageView();
    console.log(router.asPath);
  }, [router]);

  const action = (event_name, event_label) => {
    const report = {
      action: event_name,
      category: "user_interaction",
      label: event_label,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.event(report);
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const conversion = (event_name, event_label, value) => {
    const report = {
      action: event_name,
      category: "conversion",
      label: event_label,
      value: value,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.event(report);
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const success = (event_name, event_label) => {
    const report = {
      action: event_name,
      category: "operation_success",
      label: event_label,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.event(report);
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const error = (event_name, event_label) => {
    const report = {
      action: event_name,
      category: "error",
      label: event_label,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.event(report);
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const pageView = () => {
    const report = {
      action: "page_view",
      category: "page_view",
      label: router.pathname,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.send({
          hitType: "pageview",
          page: router.pathname,
        });
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const newUser = (value) => {
    const report = {
      action: "new_user",
      category: "new_user",
      label: "new_user",
      value: value ?? 1.0,
    };

    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.event(report);
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            event: report.action,
            ...report,
          },
        });
      } catch (error) {}
    }
  };

  const setUserId = (user_id) => {
    if (googleAnalyticsTrackingID) {
      try {
        ReactGA.set({ user_id: user_id });
      } catch (error) {}
    }

    if (googleTagID) {
      try {
        TagManager.dataLayer({
          dataLayerName: "gtmDataLayer",
          dataLayer: {
            user_id: user_id,
          },
        });
      } catch (error) {}
    }
  };

  const context = {
    action,
    conversion,
    success,
    error,
    pageView,
    newUser,
    setUserId,
    consent,
    setConsent,
    consentDialogOpen,
    setConsentDialogOpen,
  };

  return (
    <AnalyticsContext.Provider value={context}>
      {requireConsent && (
        <ConsentBanner
          consent={consent}
          setConsent={setConsent}
          consentDialogOpen={consentDialogOpen}
          setConsentDialogOpen={setConsentDialogOpen}
        />
      )}
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
export { AnalyticsContext };
