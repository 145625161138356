import React, { useEffect, useState, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useCookies } from "react-cookie";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
// import { Divider } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { CircularProgress } from "@mui/material";
// import Settings from "@mui/icons-material/Settings";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Login from "@mui/icons-material/Login";
import Logout from "@mui/icons-material/Logout";

import { loginRequest, b2cPolicies } from "@/utils/authConfig";
// import useIdToken from "@/utils/useIdToken";
import { TokenContext } from "@/utils/TokenProvider";
import { AnalyticsContext } from "@/utils/AnalyticsProvider";

// import apiUrlPrefix from "@/utils/apiUrlPrefix";

const Profile = () => {
  const analytics = useContext(AnalyticsContext);

  // Define Msal instance and username state.
  const { inProgress, instance } = useMsal();
  const [name, setName] = useState(null);

  // Check if user is authenticated.
  const { idToken, userId, isAuthenticated } = useContext(TokenContext);

  // Initialize cookies.
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([
    "userId",
    "templates",
    "defaultTemplateId",
  ]);

  useEffect(() => {
    if (idToken) {
      async function getTemplates() {
        try {
          // Get Environment variable for API URL prefix.
          // const apiUrlPrefix = process.env.WEB_API_URL_PREFIX;
          // console.log("API URL Prefix: ", apiUrlPrefix);
          const response = await fetch(
            "https://api.jaxen.ai/web/V2/templates",
            {
              method: "GET",
              headers: {
                "API-Key": "08551ebd17974a62acd4d4cf4a627ad8",
                Authorization: `Bearer ${idToken}`,
              },
            }
          );
          if (response.ok) {
            let data = await response.text();
            if (data !== "") {
              data = await JSON.parse(data);
            } else {
              data = await JSON.parse("{}");
            }
            return data;
          }
        } catch (error) {}
        return null;
      }

      async function getDefaultTemplateId() {
        try {
          const response = await fetch(
            "https://api.jaxen.ai/web/V2/template/default",
            {
              headers: {
                "API-Key": "08551ebd17974a62acd4d4cf4a627ad8",
                Authorization: `Bearer ${idToken}`,
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            return data.TemplateId;
          } else if (response.status === 201) {
            return null;
          }
        } catch (error) {}
        return null;
      }

      setCookie("userId", userId, {
        path: "/",
        maxAge: 1707109200, //400 day
      });

      getTemplates().then((data) => {
        if (data) {
          setCookie("templates", JSON.stringify(data), {
            path: "/",
            maxAge: 1707109200, //400 day
          });
          if (data.defaultTemplateId) {
            setCookie("defaultTemplateId", data.defaultTemplateId, {
              path: "/",
              maxAge: 1707109200, //400 day
            });
          }
        }

        getDefaultTemplateId().then((data) => {
          if (data) {
            setCookie("defaultTemplateId", data, {
              path: "/",
              maxAge: 1707109200, //400 day
            });
          } else {
            setCookie("defaultTemplateId", null, {
              path: "/",
              maxAge: 1707109200, //400 day
            });
          }
        });
      });
    } else {
      // Clear data when logged out.
      removeCookie("userId");
      removeCookie("templates");
      removeCookie("defaultTemplateId");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, idToken]);

  // Set anchor for menu to handle clicks on profile icon.
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Check if browser supports pop-ups.
  // const [supportsPopups, setSupportsPopups] = useState(false);
  // useEffect(() => {
  //   setSupportsPopups(!!window && !!window.open);
  // }, []);

  // User name initials - for profile icon.
  // const initialsRef = useRef(null);
  const [initials, setInitials] = useState(null);
  // Get user first and last name.
  useEffect(() => {
    const activeAccount = instance.getActiveAccount();
    if (activeAccount) {
      const firstNameClaim = activeAccount.idTokenClaims.given_name;
      const lastNameClaim = activeAccount.idTokenClaims.family_name;
      if (firstNameClaim && lastNameClaim) {
        const fullName = firstNameClaim + " " + lastNameClaim;
        setName(fullName);
        setInitials(firstNameClaim[0] + lastNameClaim[0]);
      } else if (firstNameClaim) {
        setName(firstNameClaim);
        setInitials(firstNameClaim[0]);
      } else {
        setName("User");
        setInitials("U");
      }
    } else {
      setName(null);
      setInitials("U");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // Handle Login
  const handleLogin = () => {
    // setAnchorEl(null);
    // if (supportsPopups) {
    //   instance.loginPopup(loginRequest);
    // } else {
    instance.loginRedirect(loginRequest);
    // }
  };

  // Handle Logout
  const handleLogout = () => {
    // if (supportsPopups) {
    //   instance.logoutPopup();
    // } else {
    //   instance.logoutRedirect();
    // }
    // instance.logoutRedirect({
    //   onRedirectNavigate: () => {
    //     return false;
    //   },
    // });
    analytics.action("navbar_logout_click");
    instance.logoutRedirect();
  };

  // Handle Profile Edit
  const handleProfileEdit = () => {
    if (userId && inProgress === InteractionStatus.None) {
      analytics.action("navbar_profile_edit_click");
      instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
    }
  };

  return (
    <>
      <Tooltip title="Account Settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ p: 0 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          // className="ml-2"
        >
          {isAuthenticated && (
            <Avatar
              // src="/images/user1.png"
              // alt={name}
              sx={{ width: 40, height: 40 }}
            >
              {userId &&
                idToken &&
                inProgress === InteractionStatus.None &&
                initials}
              {(!userId ||
                !idToken ||
                inProgress !== InteractionStatus.None) && <CircularProgress />}
            </Avatar>
          )}
          {!isAuthenticated && <Avatar />}
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "10px",
            boxShadow: "0px 10px 35px rgba(50, 110, 189, 0.2)",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="for-dark-top-navList"
      >
        {userId && (
          <MenuItem key="user-avatar">
            <Avatar className="mr-1" />
            <Box>
              <Typography color="var(--text-light)" sx={{ fontSize: "11px" }}>
                Welcome,
              </Typography>
              <Typography
                color="var(--accent2-dark)"
                sx={{
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                {name}
              </Typography>
            </Box>
          </MenuItem>
        )}
        {!userId && (
          <MenuItem onClick={() => handleLogin()} key="login">
            <ListItemIcon sx={{ mr: "-8px", mt: "-3px" }}>
              <Login fontSize="small" />
            </ListItemIcon>
            <Typography color="var(--text-dark)">Login</Typography>
          </MenuItem>
        )}
        {userId && [
          <Divider key="divider1" />,
          <MenuItem onClick={() => handleProfileEdit()} key="profile-item">
            <ListItemIcon sx={{ mr: "-8px", mt: "-3px" }}>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <Typography color="var(--text-dark)">Profile</Typography>
          </MenuItem>,
          /* <MenuItem>
              <ListItemIcon sx={{ mr: "-8px", mt: "-3px" }}>
                <MailOutlineIcon fontSize="small" />
              </ListItemIcon>
              <Link
                href="/email/inbox/"
                fontSize="13px"
                color="inherit"
                underline="none"
              >
                Inbox
              </Link>
            </MenuItem> */
          /* <MenuItem>
              <ListItemIcon sx={{ mr: "-8px", mt: "-3px" }}>
                <ChatBubbleOutlineIcon fontSize="small" />
              </ListItemIcon>
              <Link
                href="/apps/chat/"
                fontSize="13px"
                color="inherit"
                underline="none"
              >
                Chat
              </Link>
            </MenuItem> */
          /* <MenuItem>
              <ListItemIcon sx={{ mr: "-8px", mt: "-3px" }}>
                <Settings fontSize="small" />
              </ListItemIcon>
              <Link
                href="/settings/account/"
                fontSize="13px"
                color="inherit"
                underline="none"
              >
                Settings
              </Link>
            </MenuItem> */
          /* <MenuItem>
              <ListItemIcon sx={{ mr: "-8px", mt: "-3px" }}>
                <AttachMoneyIcon fontSize="small" />
              </ListItemIcon>
              <Link
                href="/pages/pricing/"
                fontSize="13px"
                color="inherit"
                underline="none"
              >
                Pricing
              </Link>
            </MenuItem> */
          /* <Divider /> */
          <MenuItem onClick={() => handleLogout()} key="logout-item">
            <ListItemIcon sx={{ mr: "-8px", mt: "-3px" }}>
              <Logout fontSize="small" />
            </ListItemIcon>
            <Typography color="var(--text-dark)">Logout</Typography>
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
};

export default Profile;
