import { createTheme } from "@mui/material/styles";
import { Comfortaa, Roboto } from "next/font/google";

const comfortaa = Comfortaa({
  weight: ["300", "400", "500"],
  subsets: ["latin"],
});

const roboto = Roboto({
  weight: ["400", "500", "700"],
  subsets: ["latin", "latin-ext"],
});

// Create a theme instance.
const theme = createTheme({
  palette: {
    text: {
      black: "hsla(0, 0%, 0%, 100%)",
      primary: "hsla(0, 0%, 0%, 80%)", //"var(--text-dark)", //becaue skeleton needs this color to be a static color
      secondary: "hsla(0, 0%, 0%, 60%)",
      light: "#fff",
      disabled: "#5B5B98",
      hint: "#5B5B98",
    },
    primary: {
      main: "hsla(276, 100%, 45%, 80%)",
    },
    secondary: {
      main: "hsla(22, 100%, 45%, 80%)",
    },
    success: {
      main: "#00B69B",
    },
    info: {
      main: "#2DB6F5",
    },
    warning: {
      main: "#FFBC2B",
    },
    danger: {
      main: "#EE368C",
    },
    error: {
      main: "#f44336",
    },
    dark: {
      main: "#260944",
    },
    linkedIn: {
      main: "#0a66c2",
      contrastText: "#fff",
    },
  },
  // typography: {
  //   fontSize: "12px",
  // },
});

theme.typography.h1 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: comfortaa.style.fontFamily,
  fontWeight: 300,
  fontSize: "4.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
  // color: theme.palette.primary.main,
};

theme.typography.h2 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: comfortaa.style.fontFamily,
  fontWeight: 400,
  fontSize: "3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.25rem",
  },
  // color: theme.palette.primary.main,
};
theme.typography.h3 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: comfortaa.style.fontFamily,
  fontWeight: 500,
  fontSize: "2.25rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
  // color: theme.palette.primary.main,
};
theme.typography.h4 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: comfortaa.style.fontFamily,
  fontWeight: 500,
  fontSize: "2.125rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.75rem",
  },
  // color: theme.palette.primary.main,
};
theme.typography.h5 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: roboto.style.fontFamily,
  fontWeight: 500,
  fontSize: "1.5rem",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.rem",
  // },
};
theme.typography.h6 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: roboto.style.fontFamily,
  fontWeight: 500,
  fontSize: "1.25rem",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.75rem",
  // },
};
theme.typography.subtitle1 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: roboto.style.fontFamily,
  fontWeight: 400,
  fontSize: "1rem",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.75rem",
  // },
};
theme.typography.subtitle2 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: roboto.style.fontFamily,
  fontWeight: 500,
  fontSize: "0.875rem",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.75rem",
  // },
};
theme.typography.body1 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: roboto.style.fontFamily,
  fontWeight: 400,
  fontSize: "1rem",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.75rem",
  // },
};
theme.typography.body2 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: roboto.style.fontFamily,
  fontWeight: 400,
  fontSize: "0.875rem",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.75rem",
  // },
};
theme.typography.button = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: roboto.style.fontFamily,
  fontWeight: 500,
  fontSize: "0.875rem",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.75rem",
  // },
};
theme.typography.caption = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: roboto.style.fontFamily,
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "1.66",
  letterSpacing: "0.03333em",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.75rem",
  // },
};
theme.typography.overline = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: roboto.style.fontFamily,
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "2.66",
  letterSpacing: "0.08333em",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.75rem",
  // },
};
// theme.components.MuiButton.styleOverrides.root.backgroundColor = "red";

export default theme;
