import React from "react";
import Home from "@mui/icons-material/Home";
import Science from "@mui/icons-material/Science";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <Home />,
    // iconClosed: <KeyboardArrowRightIcon />,
    // iconOpened: <KeyboardArrowDownIcon />,

    // subNav: [
    //   {
    //     title: "eCommerce",
    //     path: "/ecommerce/",
    //   },
    //   {
    //     title: "Analytics",
    //     path: "/analytics/",
    //   },
    //   {
    //     title: "Project Management",
    //     path: "/project-management/",
    //   },
    //   {
    //     title: "LMS Courses",
    //     path: "/lms-courses/",
    //   },
    // ],
  },
  {
    title: "MotivationLab",
    path: "/MotivationLab",
    icon: <Science />,
    requiresSubscription: true,

    subNav: [
      {
        title: "User Manual",
        path: "/UserManual",
      },
      // {
      //   title: "Analytics",
      //   path: "/analytics/",
      // },
      // {
      //   title: "Project Management",
      //   path: "/project-management/",
      // },
      // {
      //   title: "LMS Courses",
      //   path: "/lms-courses/",
      // },
    ],
  },
  {
    title: "Pricing",
    path: "/Pricing",
    icon: <AttachMoneyIcon />,
    //   iconClosed: <KeyboardArrowRightIcon />,
    //   iconOpened: <KeyboardArrowDownIcon />,

    //   // subNav: [
    //   //   {
    //   //     title: "eCommerce",
    //   //     path: "/ecommerce/",
    //   //   },
    //   //   {
    //   //     title: "Analytics",
    //   //     path: "/analytics/",
    //   //   },
    //   //   {
    //   //     title: "Project Management",
    //   //     path: "/project-management/",
    //   //   },
    //   //   {
    //   //     title: "LMS Courses",
    //   //     path: "/lms-courses/",
    //   //   },
    //   // ],
  },
  {
    title: "Contact Us",
    path: "/Contact",
    icon: <ContactSupportIcon />,
  },
];
